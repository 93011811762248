export const Gateway = (props) => {
  return (
    <div>
      Gateways
      <ul>
        <li>api.interstate.so</li>
        <li>http://135.181.191.125:58017</li>
      </ul>
    </div>
  )
}
